<template>
  <footer>
    <div class="container footer-container">
      <div class="footer-left">
        <img src="~/static/images/werbe-logo-white.svg" alt="Werbeartikel Pro" class="logo">
        <div class="footer-buttons buttons">
          <nuxt-link class="footer-buttons-link" :to="_user && check('seller') ? localePath('admin-messenger') : localePath('messenger')">
            <b-button icon-left="messages" rounded class="footer-buttons-link-message">
              {{ $t('footer.contactViaMessenger') }}
            </b-button>
          </nuxt-link>

          <div class="buttons">
            <a href="mailto:info@werbeartikel-pro.de">
              <b-button icon-left="email" class="social-button" rounded />
            </a>
            <a href="https://www.facebook.com/werbeartikelpro" target="_blank">
              <b-button icon-left="facebook" class="social-button" rounded />
            </a>
            <a href="https://plus.google.com/105492882357320264761/about" target="_blank">
              <b-button icon-left="google" class="social-button" rounded />
            </a>
          </div>
        </div>
        <div class="footer-payment-icons">
          <img src="~/static/images/visa.svg" alt="">
          <img src="~/static/images/mastercard.svg" alt="">
          <img src="~/static/images/apple-pay.svg" alt="">
          <img src="~/static/images/google-pay.svg" alt="">
          <img src="~/static/images/mollie.svg" alt="">
          <img src="~/static/images/paypal.svg" alt="">
        </div>
      </div>
      <div class="footer-right">
        <div class="footer-section">
          <h3>{{ $t('footer.info') }}</h3>
          <ul>
            <li>
              <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: 'versand'}})">
                {{ $t('footer.shipping') }}
              </nuxt-link>
            </li>
            <li>
              <nuxt-link :to="localePath({name: 'cmyk-farben'})">
                {{ $t('footer.cmykFarben') }}
              </nuxt-link>
            </li>
          </ul>
        </div>
        <div class="footer-section">
          <h3>{{ $t('footer.help') }}</h3>
          <ul>
            <li>{{ $t('footer.decorateProducts') }}</li>
            <li>{{ $t('footer.prepareFileForPrint') }}</li>
          </ul>
        </div>
        <div class="footer-section">
          <h3>{{ $t('footer.otherShortcuts') }}</h3>
          <ul>
            <li>
              <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: 'allgemeine-geschaftsbedingungen'}})">
                {{ $t('footer.generalTerms') }}
              </nuxt-link>
            </li>
            <li>
              <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: 'datenschutzerklarung'}})">
                {{ $t('footer.privacyPolicy') }}
              </nuxt-link>
            </li>
            <li>
              <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: 'werbeartikel-bestpreis-garantie'}})">
                {{ $t('footer.priceGuarantee') }}
              </nuxt-link>
            </li>
          </ul>
        </div>
        <div class="footer-section">
          <h3>{{ $t('footer.aboutUs') }}</h3>
          <ul>
            <li>
              <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: 'uber-uns'}})">
                {{ $t('footer.aboutUs') }}
              </nuxt-link>
            </li>
            <li>
              <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: 'kontakt'}})">
                {{ $t('footer.contact') }}
              </nuxt-link>
            </li>
            <li>
              <nuxt-link :to="localePath({name: 'cms-postPage', params: {slug: 'impressum'}})">
                {{ $t('footer.imprint') }}
              </nuxt-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  methods: {
    check (key) {
      if (this._user) {
        let availableRoles = []
        switch (key) {
          case 'seller':
            availableRoles = ['ROLE_SELLER_OWNER', 'ROLE_SELLER']
            break
          case 'user':
            availableRoles = ['ROLE_SUPER_ADMIN', 'ROLE_USER']
            break
        }
        const check = Object.values(availableRoles).filter((val) => {
          return this._user.roles.includes(val)
        })

        return check.length > 0
      } else {
        return false
      }
    }
  }
}
</script>
