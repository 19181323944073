var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('nuxt-link',{attrs:{"to":_vm.localePath({
      name: 'products-category',
      params: {
        categoryName: _vm.category.slug || _vm.toSlugTitle(_vm.category.description),
        categoryId: _vm.category.id
      },
    })},nativeOn:{"click":function($event){return _vm.$store.dispatch('page/update', { name: 'isMenuOpen', data: false })}}},[_c('div',{staticClass:"menu-item"},[_c('div',{staticClass:"category-icon",style:(`background-color: ${_vm.category.color || '#E3D8D0'}`)},[(_vm.category.image)?_c('b-image',{attrs:{"ratio":"1by1","src":_vm.category.image.webp || _vm.category.image.file,"webp-fallback":_vm.category.image.webp ? _vm.category.image.file : null,"lazy":"","width":"100","height":"100"}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"category-name"},[_vm._v("\n        "+_vm._s(_vm.category.description.name)+"\n      ")])])]),_vm._v(" "),_c('div',{staticClass:"category-subitems"},_vm._l((_vm.category.children.slice(0, 3)),function(subcategory,indexSubCategory){return _c('div',{key:indexSubCategory,staticClass:"category-subitem",class:!subcategory.image ? 'catgory-margin' : ''},[_c('div',{staticClass:"category-icon",style:(`background-color: ${_vm.category.color || '#E3D8D0'}`)},[(subcategory.image)?_c('b-image',{attrs:{"ratio":"1by1","src":subcategory?.image?.webp || subcategory?.image?.file,"webp-fallback":subcategory?.image.webp ? subcategory?.image?.file : null,"lazy":"","width":"100","height":"100"}}):_vm._e()],1),_vm._v(" "),_c('nuxt-link',{staticClass:"category-subitem-name",attrs:{"to":_vm.localePath({
          name: 'products-category',
          params: {
            categoryName: subcategory.slug || _vm.toSlugTitle(subcategory.description),
            categoryId: subcategory.id
          },
        })}},[_vm._v("\n        "+_vm._s(subcategory.description.name)+"\n      ")])],1)}),0),_vm._v(" "),(_vm.category.children && _vm.category.children.length > 3)?_c('nuxt-link',{staticClass:"category-more",attrs:{"to":_vm.localePath({
      name: 'products-category',
      params: {
        categoryName: _vm.category.slug || _vm.toSlugTitle(_vm.category.description),
        categoryId: _vm.category.id
      },
    })}},[_vm._v("\n    "+_vm._s(_vm.$t('actions.showMore'))+"\n  ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }