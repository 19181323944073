<template>
  <div>
    <nuxt-link
      :to="localePath({
        name: 'products-category',
        params: {
          categoryName: category.slug || toSlugTitle(category.description),
          categoryId: category.id
        },
      })"
      @click.native="$store.dispatch('page/update', { name: 'isMenuOpen', data: false })"
    >
      <div class="menu-item">
        <div class="category-icon" :style="`background-color: ${category.color || '#E3D8D0'}`">
          <b-image
            v-if="category.image"
            ratio="1by1"
            :src="category.image.webp || category.image.file"
            :webp-fallback="category.image.webp ? category.image.file : null"
            lazy
            width="100"
            height="100"
          />
          <!-- <img :src="require(`~/static/images/categories/${item.image}.png`)" alt="" width="100" height="100"> -->
        </div>
        <div class="category-name">
          {{ category.description.name }}
        </div>
      </div>
    </nuxt-link>
    <div class="category-subitems">
      <div
        v-for="(subcategory, indexSubCategory) in category.children.slice(0, 3)"
        :key="indexSubCategory"
        class="category-subitem"
        :class="!subcategory.image ? 'catgory-margin' : ''"
      >
        <div class="category-icon" :style="`background-color: ${category.color || '#E3D8D0'}`">
          <b-image
            v-if="subcategory.image"
            ratio="1by1"
            :src="subcategory?.image?.webp || subcategory?.image?.file"
            :webp-fallback="subcategory?.image.webp ? subcategory?.image?.file : null"
            lazy
            width="100"
            height="100"
          />
        </div>
        <nuxt-link
          class="category-subitem-name"
          :to="localePath({
            name: 'products-category',
            params: {
              categoryName: subcategory.slug || toSlugTitle(subcategory.description),
              categoryId: subcategory.id
            },
          })"
        >
          {{ subcategory.description.name }}
        </nuxt-link>
      </div>
    </div>

    <nuxt-link
      v-if="category.children && category.children.length > 3"
      class="category-more"
      :to="localePath({
        name: 'products-category',
        params: {
          categoryName: category.slug || toSlugTitle(category.description),
          categoryId: category.id
        },
      })"
    >
      {{ $t('actions.showMore') }}
    </nuxt-link>
  </div>
</template>

<script>
export default {
  props: {
    category: {
      type: Object,
      default: () => { }
    }
  }
}
</script>
<style lang="scss" scoped>
.category-subitem-name {
  text-decoration: none;
  width: 100%;
  font-size: inherit;
}
</style>
