export const coreState = () => ({
  cart: null,
  changedCart: 0,
  countNewMessages: null,
  isOpenedCart: false,
  loadingChangeCart: false,
  loadingCart: false,
  loadingProductsPage: false,
  isMenuOpen: false,
  isCategoryOpen: false,
  locale: null,
  currency: null,
  countries: [],
  categories: [],
  countryId: null,
  mainAddress: null,
  addresses: null,
  breadcrumbs: null,
  productScroll: false
})

export const coreMutations = {
  update (store, { name, data }) {
    store[name] = data
  },
  changedCart (store) {
    store.changedCart++
  },
  isOpenedCartToggle (store) {
    store.isOpenedCart = !store.isOpenedCart
  },
  loadingChangeCart (store, data) {
    if (data) {
      store.loadingChangeCart++
    } else {
      store.loadingChangeCart--
    }
  },
  isMenuOpen (store, data) {
    store.isMenuOpen = data
  },
  isCategoryOpen (store, data) {
    store.isCategoryOpen = data
  },
  locale (store, data) {
    store.locale = data
  },
  productScroll (store, data) {
    store.productScroll = data
  }
}

export const coreActions = {
  update ({ commit }, data) {
    commit('update', data)
  },
  changedCart ({ commit }) {
    commit('changedCart')
  },
  isOpenedCartToggle ({ commit }) {
    commit('isOpenedCartToggle')
  },
  loadingChangeCart ({ commit }, data) {
    commit('loadingChangeCart', data)
  },
  isMenuOpen ({ commit }, data) {
    commit('isMenuOpen', data)
  },
  isCategoryOpen ({ commit }, data) {
    commit('isCategoryOpen', data)
  },
  locale ({ commit }, data) {
    commit('locale', data)
  },
  productScroll ({ commit }, data) {
    commit('productScroll', data)
  }
}
